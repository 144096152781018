<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header pt-10 pb-10">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ข้อมูลผู้ใช้งาน / Profile
      </h3>
      <div class="card-toolbar">
        <button
          v-if="roleUser === 'ROOT'"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่ม System Admin
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table class="table table-hover table-row-dashed gy-7 gs-7">
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>User ID</th>
              <th>ชื่อผู้ใช้งาน</th>
              <th>เบอร์โทรศัพท์</th>
              <th>สถานะ</th>
              <th v-if="isGotoNew"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.user_code }}</td>
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.telNumber }}</td>
              <td>
                <!-- {{ item.statusActive }} -->
                <button
                  style="padding: 5px; width: 80px; cursor: default"
                  :class="item.statusActive ? class1 : class2"
                >
                  {{ item.statusActive ? "Active" : "Inactive" }}
                </button>
              </td>
              <td v-if="isGotoNew">
                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";

import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";

export default {
  components: {
    Pagination,
    Loader,
  },
  setup() {
    document.title = "BELUCA | ข้อมูลผู้ใช้งาน";
  },
  data: () => ({
    userId: "",
    user: [],
    classRole: "badge badge-lg badge-light-success fw-bolder fs-6",

    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    isGotoNew: false,

    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],

    roleUser: "",

    class1: "btn btn-sm btn-light-success fw-boldest",
    class2: "btn btn-sm btn-light fw-boldest",
  }),
  created() {
    this.getAllUser();
    this.checkRole();
  },
  methods: {
    async getAllUser() {
      this.loading = true;
      let responseData = [];

      const role = Decode.decode(localStorage.getItem("role")).replace(
        /^"(.*)"$/,
        "$1"
      );

      this.roleUser = role;

      const confirm =
        role === "SYSTEN ADMIN" ? "confirm" : role === "ROOT" ? "confirm" : "";

      try {
        responseData = await userApi.user.getAll(confirm);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    checkRole() {
      if (
        this.roleUser === "STAFF" ||
        this.roleUser === "MANAGER" ||
        this.roleUser === "BUSINESS DIRECTOR"
      ) {
        this.isGotoNew = false;
      } else {
        this.isGotoNew = true;
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push({
        path: "/setting/user-profile-new",
      });
    },

    goToEdit(id) {
      this.$router.push({
        path: "/setting/user-profile-detail",
        query: {
          id: id,
        },
      });
    },

    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.username} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await userApi.user.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAllUser(1);
        });
      }
    },

    setClassRole() {
      this.user["role"] === "SYSTEM ADMIN"
        ? (this.classRole = "badge badge-lg badge-light-primary fw-bolder fs-6")
        : this.user["role"] === "BUSINESS OWNER"
        ? (this.classRole = "badge badge-lg badge-light-success fw-bolder fs-6")
        : this.user["role"] === "BUSINESS DIRECTOR"
        ? (this.classRole = "badge badge-lg badge-light-warning fw-bolder fs-6")
        : this.user["role"] === "MANAGER"
        ? (this.classRole = "badge badge-lg badge-light-danger fw-bolder fs-6")
        : this.user["role"] === "STAFF"
        ? (this.classRole = "badge badge-lg badge-light-info fw-bolder fs-6")
        : (this.classRole =
            "badge badge-lg badge-light-success fw-bolder fs-6");
    },
  },
};
</script>
